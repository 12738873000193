/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import googleCalendarPlugin from '@fullcalendar/google-calendar';
import timeGridPlugin from '@fullcalendar/timegrid';

// reactstrap components
import { Button, Card, Container, Row, Col } from "reactstrap";

// core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import CardsFooter from "components/Footers/CardsFooter.js";

class Schedule extends React.Component {
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    return (
      <>
        <DemoNavbar />
        <main className="profile-page" ref="main">
          <section className="section section-lg section-shaped pb-200">
            {/* Circles background */}
            <div className="shape shape-style-3 shape-default alpha-4">
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
            </div>
            <Container className="py-lg-md d-flex">
              <div className="col px-0">
                <Row>
                  <Col lg="6">
                    {/* <p className="lead text-white">
                      <span><a className="lead text-white" href="https://www.youtube.com/c/justxn/videos">Click here to start watching</a></span>
                    </p> */}
                    
                    <p className="lead text-white">
                      <div>No timezone conversion required.</div>
                      <Button
                        color="default"
                        href="sms-signup"
                      >
                        <div>Sign up for SMS notifications!</div>
                      </Button>
                      <Button
                        color="default"
                        href="https://bit.ly/SubToStudyCal"
                      >
                        <div>Add to calendar</div>
                      </Button>
                    </p>
                    <div className="btn-wrapper">
                      {/* <Button
                          className="btn-icon mb-3 mb-sm-0"
                          color="info"
                          href="https://demos.creative-tim.com/argon-design-system-react/#/documentation/alerts?ref=adsr-landing-page"
                        >
                          <span className="btn-inner--icon mr-1">
                            <i className="fa fa-code" />
                          </span>
                          <span className="btn-inner--text">Follow my Journey</span>
                        </Button> */}
                      {/* <Button
                          className="btn-white btn-icon mb-3 mb-sm-0 ml-1"
                          color="default"
                          href="https://www.creative-tim.com/product/argon-design-system-react?ref=adsr-landing-page"
                        >
                          <span className="btn-inner--icon mr-1">
                            <i className="ni ni-cloud-download-95" />
                          </span>
                          <span className="btn-inner--text">
                            About me
                          </span>
                        </Button> */}
                    </div>
                  </Col>
                </Row>
              </div>
            </Container>
            {/* SVG separator */}
            <div className="separator separator-bottom separator-skew">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-white"
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div>
          </section>
          <section className="section">
            <Container>
              <Card className="card-profile shadow mt--300 d-none d-md-block" >
                <div className="m-4">
                  <div class="g-ytsubscribe" data-channelid="UCxJZJCNLru2X5323JmwOlUg" data-layout="full" data-theme="dark" data-count="default"></div>
                  {/* googleCalendarApiKey: 'AIzaSyDcnW6WejpTOCffshGDDb4neIrXVUA1EAE',

                  // US Holidays
                  events: 'en.usa#holiday@group.v.calendar.google.com', */}
                  <FullCalendar
                    plugins={[googleCalendarPlugin, timeGridPlugin]}
                    googleCalendarApiKey={'AIzaSyB-O-meWFWjd6cw6rCyDx-08uYS2MO8nC4'}
                    events= {
                      { googleCalendarId: 'v9m1ieb8s7d68jegisr3bci6fc@group.calendar.google.com' }
                    }
                    height={'800px'}
                    initialView={ 'timeGridWeek'}
                    updateSize
                    nowIndicator
                    expandRows
                    slotDuration={"01:00:00"}
                    // slotMinTime={"08:00:00"}
                    slotMaxTime={"24:00:00"}
                  />
                </div>
              </Card>
              <Card className="card-profile shadow mt--300 d-md-none" >
                <div class="g-ytsubscribe" data-channelid="UCxJZJCNLru2X5323JmwOlUg" data-layout="full" data-theme="dark" data-count="default"></div>
                <div className="m-4">
                  {/* googleCalendarApiKey: 'AIzaSyDcnW6WejpTOCffshGDDb4neIrXVUA1EAE',

                  // US Holidays
                  events: 'en.usa#holiday@group.v.calendar.google.com', */}
                  <FullCalendar
                    plugins={[googleCalendarPlugin, timeGridPlugin]}
                    googleCalendarApiKey={'AIzaSyB-O-meWFWjd6cw6rCyDx-08uYS2MO8nC4'}
                    events= {
                      { googleCalendarId: 'v9m1ieb8s7d68jegisr3bci6fc@group.calendar.google.com' }
                    }
                    height={'800px'}
                    initialView={ 'timeGridDay'}
                    updateSize
                    nowIndicator
                    expandRows
                    slotDuration={"01:00:00"}
                    // slotMinTime={"08:00:00"}
                    slotMaxTime={"24:00:00"}
                  />
                </div>
              </Card>
            </Container>
          </section>
        </main>
        <CardsFooter />
      </>
    );
  }
}

export default Schedule;
