/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Container,
    Row,
    Col
} from "reactstrap";

// core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import SimpleFooter from "components/Footers/SimpleFooter.js";
import CardsFooter from "components/Footers/CardsFooter";

// external components
// import MuiPhoneNumber from "material-ui-phone-number"

class SmsRegister extends React.Component {
    // constructor() {
    //   this.state = { consent: false };
    // }

    state = {
        isConsent: false,
        status: 0
    }

    handleConsent = () => {
        console.log("hello")
        this.setState({
            isConsent: !this.state.isConsent
        })
    }

    setSubmitStatus = (status) => {
        this.setState({
            status: 1
        })
    }

    componentDidMount() {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
        this.refs.main.scrollTop = 0;
    }

    render() {
        const formURL = "https://docs.google.com/forms/u/0/d/e/1FAIpQLSf7K7n8xXVUrKjfQSPgoIaD7pNnlaTprM49WCFsdxJxKPynQQ/formResponse";
        return (
            <>
                <DemoNavbar />
                <main ref="main">
                    <section className="section section-shaped section-lg">
                        <div className="shape shape-style-1 bg-gradient-default">
                            <span />
                            <span />
                            <span />
                            <span />
                            <span />
                            <span />
                            <span />
                            <span />
                        </div>
                        <Container className="pt-lg-7">
                            <iframe style={{ position: 'absolute', width: '0', height: '0', border: '0' }} title="iframe_1" src="demo_iframe.htm" name="iframe_a"></iframe>

                            <Row className="justify-content-center">
                                <Col lg="5">
                                    <Card className="bg-secondary shadow border-0">
                                        <CardBody className="px-lg-5 py-lg-5">
                                            <div className="text-center text-muted mb-4">
                                                <small>Sign up here for daily SMS notifications! (Due to high demand, this service temporarily unavailable.) </small>
                                            </div>
                                            <Form role="form" target="iframe_a" action={formURL} method="POST" onSubmit={() => this.setSubmitStatus(1)} >
                                                <FormGroup>
                                                    <InputGroup className="input-group-alternative mb-3">
                                                        <InputGroupAddon addonType="prepend">
                                                            <InputGroupText>
                                                                <i className="ni ni-hat-3" />
                                                            </InputGroupText>
                                                        </InputGroupAddon>
                                                        <Input required name="entry.1928600510" placeholder="Name" type="text" />
                                                    </InputGroup>
                                                </FormGroup>
                                                <FormGroup>
                                                    <InputGroup className="input-group-alternative mb-3">
                                                        <InputGroupAddon addonType="prepend">
                                                            <InputGroupText>
                                                                <i className="ni ni-email-83" />
                                                            </InputGroupText>
                                                        </InputGroupAddon>
                                                        <Input
                                                            required name="entry.1411621146"
                                                            placeholder="Email"
                                                            type="email" />
                                                    </InputGroup>
                                                </FormGroup>
                                                <FormGroup>
                                                    <InputGroup className="input-group-alternative">
                                                        <InputGroupAddon addonType="prepend">
                                                            <InputGroupText>
                                                                <i className="ni ni-bell-55" />
                                                            </InputGroupText>
                                                        </InputGroupAddon>
                                                        <Input
                                                            required
                                                            name="entry.733999061"
                                                            type="phone"
                                                            placeholder="+1 (XXX)-XXX-XXXX"

                                                        />
                                                    </InputGroup>
                                                </FormGroup>

                                                {/* <div className="text-muted font-italic">
                          <small>
                            password strength:{" "}
                            <span className="text-success font-weight-700">
                              strong
                            </span>
                          </small>
                        </div> */}
                                                <Row className="my-4">
                                                    <Col xs="12">
                                                        <div className="custom-control custom-control-alternative custom-checkbox">
                                                            <input
                                                                className="custom-control-input"
                                                                id="customCheckRegister"
                                                                type="checkbox"
                                                                onClick={() => this.handleConsent()}
                                                            />
                                                            <label
                                                                className="custom-control-label"
                                                                htmlFor="customCheckRegister"
                                                            >
                                                                <span>
                                                                    I consent to the receipt of text messages from Justxn.com {" "}
                                                                    <a
                                                                    // href="#pablo"
                                                                    >
                                                                        {/* I agree to receive SMS reminder notifications */}
                                                                    </a>
                                                                </span>
                                                            </label>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <div className="text-center">
                                                    <Button
                                                        className="mt-4"
                                                        color="primary"
                                                        type="button"
                                                        type="submit"
                                                        disabled={!this.state.isConsent || this.state.submitStatus}
                                                        onClick={() => this.state.submitStatus = 1}
                                                    >
                                                        {this.state.submitStatus ? 'Yay! You will receive text reminders!' : 'Subscribe'}
                                                    </Button>
                                                </div>
                                            </Form>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                </main>
                <CardsFooter />
            </>
        );
    }
}

export default SmsRegister;